import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointMd} from '@/tokens/variables.js'

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);

export const style = css`
  :host {

  }

  div {
    flex: 1 0 25%;
  }

  @media screen and (max-width: ${breakpointMd}) {
    div {
      flex: 1;
    }
  }
`;
