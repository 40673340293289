import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointMd, sbkCoreBreakpointLg} from '@/tokens/variables.js'

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const style = css`
  :host {
    --footer-link-container__gap--sm : var(--_footer-link-container__gap--sm, var(--sbkFooterSmallScrLinkContainerGap));
    --footer-link-container__gap--lg : var(--_footer-link-container__gap--lg, var(--sbkCoreSizingMd));
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    gap: var(--footer-link-container__gap--sm);
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  @media screen and (min-width: ${breakpointMd}) {
    ul {
      flex-direction: row;
      gap: var(--footer-link-container__gap--lg);
    }
  }

  @media screen and (min-width: ${breakpointLg}) {
    ul {
      justify-content: center;
    }
  }
`;
