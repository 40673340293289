import { customElement, property } from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import { style } from './service-navigation-item.styles';


@customElement('sbk-service-navigation-item')
export class SbkServiceNavigationItem extends LitElement {

    @property()
    href = '';

    static get styles() {
        return style;
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div>
                <sbk-link href=${this.href} variant="regular" size="xs">
                    <slot></slot>
                </sbk-link>
            </div>
        `;
    }
}
